import React, { useState } from "react";
import {
  AdvancedMarker,
  InfoWindow,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
import { orderFormatedDate } from "../../utils/dateUtil";
import AppIcon from "../../assets/logo.jpeg";

function MarkerWithInfowindow(props) {
  const [infowindowOpen, setInfowindowOpen] = useState(false);
  const [markerRef, marker] = useAdvancedMarkerRef();

  function statusText(status) {
    switch (status) {
      case "AVAILABLE":
        return <span style={{ color: "green" }}>AVAILABLE</span>;

      case "BUSY":
        return <span style={{ color: "violet" }}>BUSY</span>;

      case "OFF":
        return <span style={{ color: "red" }}>OFF</span>;
    }
  }
  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        onClick={() => setInfowindowOpen(true)}
        position={{
          lat: parseFloat(props.item.db_latitude),
          lng: parseFloat(props.item.db_longitude),
        }}
        title={props.item.name}
      >
        <img
          src={props.item.image_path !== "" ? props.item.image_path : AppIcon}
          width={50}
          height={50}
          className="card-1"
          style={{ padding: "5px" }}
        />
      </AdvancedMarker>
      {infowindowOpen && (
        <InfoWindow
          anchor={marker}
          maxWidth={250}
          minWidth={250}
          onCloseClick={() => setInfowindowOpen(false)}
          headerContent={<h6>{props.item.name}</h6>}
        >
          <div className="d-flex">
            <img
              src={
                props.item.image_path !== "" ? props.item.image_path : AppIcon
              }
              width={50}
              height={50}
              style={{ padding: "5px" }}
            />
            <div>
              <h6>
                {props.item.name} -- {statusText(props.item.db_status)}
              </h6>
              Last updated Time
              <br />
              <b>{orderFormatedDate(props.item.last_updated)}</b>
            </div>
          </div>
        </InfoWindow>
      )}
    </>
  );
}

export default MarkerWithInfowindow;
