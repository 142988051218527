import { initializeApp } from "firebase/app";
import { getToken, getMessaging, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyC9sHSXjVFt0Y7p6kHIOQIbV7HTOjT2tZw",
  authDomain: "transchem-37b8b.firebaseapp.com",
  projectId: "transchem-37b8b",
  storageBucket: "transchem-37b8b.appspot.com",
  messagingSenderId: "29309210633",
  appId: "1:29309210633:web:0ac54213a762fdcde3e8d8",
  measurementId: "G-FDJ4WRRX0B",
};

const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);

export const getOrRegisterServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    return window.navigator.serviceWorker
      .getRegistration("/firebase-push-notification-scope")
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register(
          "firebase-messaging-sw.js",
          {
            scope: "/firebase-push-notification-scope",
          }
        );
      });
  }
  throw new Error("The browser doesn`t support service worker.");
};

export const getFirebaseToken = () =>
  getOrRegisterServiceWorker().then((serviceWorkerRegistration) =>
    getToken(messaging, {
      vapidKey:
        "BBsGFO8Z7SdmSpo2_AWDpQel9YVWJEUQUMYru6SZOQCzxX6nVtUGt8IUbFSsb-5EFLrl2pihK_zQkduyphwVJQw",
      serviceWorkerRegistration,
    })
  );

export const onForegroundMessage = () =>
  new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)));
