import { useState, memo, useRef, useEffect } from "react";
import {
  FaCheck,
  FaEdit,
  FaEye,
  FaImage,
  FaPlus,
  FaRegEdit,
  FaRegWindowClose,
  FaTrash,
  FaWindowClose,
} from "react-icons/fa";
import { useDispatch } from "react-redux";
import { updateFormInfo } from "../../../redux/slice/formUpdateSlice";
import { createUpdateAction } from "../../../network/store/action/ProductResponseAction";
import { updateMessage } from "../../../redux/slice/toastSlice";
import { Link, useNavigate } from "react-router-dom";
import noImage from "../../../assets/no_image.jpg";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { isDsplayAddProduct } from "../../../utils/userRoles";
import { productPromoImageUplaodRequest } from "../../../network/service/ImageUploadService";
import StoreProductListItem from "./store-product-list-item";
import { priceCreateUpdateAction } from "../../../network/store/action/ProductPriceAction";
import { MdClear, MdClose } from "react-icons/md";

const StorePPriceInlineItem = (props) => {
  const [price, setItem] = useState(props.itemPrice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [itemStatus, setItemStatus] = useState(price.product_availability);

  const [isEditing, setEditing] = useState(false);

  const [dataForm, setDataForm] = useState({
    id: price.id,
    productId: price.product_id,
    price: price.price,
    offer: price.offer,
    b2bprice: price.b2bprice,
    b2boffer: price.b2boffer,
    storeId: props.storeId,
  });

  const editItem = () => {
    // dispatch(updateFormInfo({ displayForm: true, formData: { item } }));
    //  navigate("/spu", { state: { item: price } });

    setEditing(true);
  };

  const closeItem = () => {
    // dispatch(updateFormInfo({ displayForm: true, formData: { item } }));
    //  navigate("/spu", { state: { item: price } });

    setEditing(false);
  };

  const onItemStatusChange = async () => {
    let status = "YES";
    if (price.product_availability == "NO") {
      status = "YES";
    } else {
      status = "NO";
    }

    let requestBody = {
      product_availability: status,
      id: price.id,
      storeId: props.storeId,
    };

    await dispatch(priceCreateUpdateAction(requestBody)).then((response) => {
      if (response.status == true) {
        setItem(price, (price.product_availability = status));
        setItemStatus(status);
      } else {
        dispatch(
          updateMessage({
            display: true,
            message: "Error While updating info",
          })
        );
      }
    });
  };

  const handleInputChanges = (e) => {
    setDataForm({
      ...dataForm,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmtClick = async () => {
    //   if (dataForm.id !== "" && dataForm.storeId !== "") {
    let requestBody = dataForm;
    await dispatch(priceCreateUpdateAction(requestBody)).then((response) => {
      if (response.status == true) {
        price.price = response.result.price;
        price.offer = response.result.offer;
        price.b2bprice = response.result.b2bprice;
        price.b2boffer = response.result.b2boffer;
        closeItem();
      } else {
      }
    });
    //   }
  };

  return (
    <>
      <td>
        {price.qty} {price.measure}
      </td>
      <td>
         {price.dimension}
      </td>
      <td onClick={() => editItem()}>
        {" "}
        {isEditing ? (
          <input
            type="number"
            name="b2bprice"
            className="form-control"
            value={dataForm.b2bprice}
            onChange={(e) => handleInputChanges(e)}
          />
        ) : (
          <>₹{price.b2bprice}</>
        )}
      </td>
      <td onClick={() => editItem()}>
        {isEditing ? (
          <input
            type="number"
            name="b2boffer"
            className="form-control"
            value={dataForm.b2boffer}
            onChange={(e) => handleInputChanges(e)}
          />
        ) : (
          <>{price.b2boffer}% OFF</>
        )}{" "}
      </td>
      <td onClick={() => editItem()}>
        {" "}
        {isEditing ? (
          <input
            type="number"
            name="price"
            className="form-control"
            value={dataForm.price}
            onChange={(e) => handleInputChanges(e)}
          />
        ) : (
          <>₹{price.price}</>
        )}
      </td>
      <td onClick={() => editItem()}>
        {isEditing ? (
          <input
            type="number"
            name="offer"
            className="form-control"
            value={dataForm.offer}
            onChange={(e) => handleInputChanges(e)}
          />
        ) : (
          <>{price.offer}% OFF</>
        )}{" "}
      </td>
      <td className="align-middle">
        {/* <p>{item.status}</p> */}
        <div class="form-check form-switch col-lg-12 d-flex justify-content-center">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            onChange={onItemStatusChange}
            checked={itemStatus === "YES" ? true : false}
          />
        </div>
      </td>

      <td>
        <p className="col-lg-12 d-flex justify-content-center">
          {isEditing ? (
            <>
              <span
                className="edit-icon-btn"
                onClick={() => {
                  onSubmtClick();
                }}
              >
                <FaCheck />
              </span>
              <span
                className="edit-icon-btn"
                onClick={() => {
                  closeItem();
                }}
              >
                <MdClose />
              </span>
            </>
          ) : (
            <span
              className="edit-icon-btn"
              onClick={() => {
                editItem();
              }}
            >
              <FaRegEdit />
            </span>
          )}
        </p>
      </td>
    </>
  );
};

export default StorePPriceInlineItem;
