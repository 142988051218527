import { useRef } from "react";
import ReactToPrint from "react-to-print";
import AppLogo from "../../assets/logo.jpeg";

function Wallet() {
  const componentRef = useRef();
  return (
    <div>
      <div ref={componentRef}>
        <img src={AppLogo}></img>
        <h5>TRANSCHEM ADMIN</h5>
      </div>
      <ReactToPrint
        trigger={() => <button>Print this out!</button>}
        content={() => componentRef.current}
      />
    </div>
  );
}
export default Wallet;
