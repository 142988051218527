import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  listAction,
  storeListAction,
} from "../../../network/store/action/ProductResponseAction";
import { listAction as categoryListAction } from "../../../network/store/action/SectionResponseAction";
import GMCTPagination from "../../../components/pagination";

import "../products.css";
import { useNavigate } from "react-router-dom";
import ProductListItemTwo from "../product-list-item";
import {
  formUpdateData,
  formUpdateStatus,
  imageUploadStatus,
} from "../../../redux/slice/formUpdateSlice";
import { isDsplayAddProduct, isStoreLogin } from "../../../utils/userRoles";
import StorePrdctInlneListItems from "./store-products-inline-list-item";
import { listAction as storesAction } from "../../../network/store/action/BranchResponseAction";
import { changeStatus } from "../../../redux/slice/loaderSlice";

function StoreInlineProducts(props) {
  // to get api data

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [sectionList, setSectionList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [branches, setBranchList] = useState([]);

  // list of items
  const [resultList, setResultList] = useState([]);
  // progress Dialog
  const [isLoading, setIsLoading] = useState(false);

  //Pagination
  const [totalCount, setTotalCount] = useState(0);
  const [pageLimit, setPageLimit] = useState(20);
  const [pageOffset, setPageOffset] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const [productAddForm, setproductAddForm] = useState({
    sectionId: "",
    categoryId: "",
    storeId: "",
  });

  const [isPriceUpdate, setIsPriceUpdate] = useState(false);
  const [isProductUpdate, setIsProductUpdate] = useState(false);

  useEffect(() => {
    getSections();
    getStores();
    getProducts(
      pageLimit,
      pageOffset,
      productAddForm.sectionId,
      productAddForm.categoryId,
      productAddForm.storeId
    );
  }, []);

  useEffect(() => {
    getProducts(
      pageLimit,
      pageOffset,
      productAddForm.sectionId,
      productAddForm.categoryId,
      productAddForm.storeId
    );
  }, [pageLimit, pageOffset, productAddForm, isPriceUpdate, isProductUpdate]);

  const getSections = async () => {
    await dispatch(categoryListAction({}, dispatch)).then((response) => {
      if (response.result) {
        setSectionList(response.result);
      }
    });
  };

  const getStores = async () => {
    await dispatch(storesAction({}, dispatch)).then((response) => {
      if (response.result) {
        setBranchList(response.result);
      }
    });
  };

  const getProducts = async (limit, offset, sectionId, categoryId, storeId) => {
    setResultList([]);
    dispatch(changeStatus(true));
    await dispatch(
      storeListAction(
        {
          limit: limit,
          offset: offset,
          sectionId: sectionId,
          categoryId: categoryId,
          storeId: storeId,
          userId: "",
        },
        dispatch
      )
    ).then((reponse) => {
      dispatch(changeStatus(false));
      if (reponse.result) {
        setResultList(reponse.result);
        setTotalCount(Number(reponse.itemCount.totalFilterCount));
        setPageCount(reponse.itemCount.pageCount);
      } else {
        resetFilter();
      }
    });
  };

  const resetFilter = () => {
    setPageOffset(0);
    setTotalCount(0);
    setResultList([]);
    setPageCount(0);
    setActivePage(1);
  };

  const handlePageChange = (selectedPage) => {
    setActivePage(selectedPage);
    setPageOffset((selectedPage - 1) * pageLimit);
  };

  const handleLimitChange = (e) => {
    setPageLimit(e.target.value);
    setPageOffset(0);
    setActivePage(1);
  };

  const handleChanges = (e) => {
    if (e.target.name === "sectionId") {
      const sectionIdex = sectionList.findIndex(
        (obj) => obj.id === e.target.value
      );
      if (e.target.value == "") {
        setCategoryList([]);
      } else {
        setCategoryList(sectionList[sectionIdex].categories);
      }

      setproductAddForm({
        ...productAddForm,
        categoryId: "",
        sectionId: e.target.value,
      });
    } else {
      setproductAddForm({ ...productAddForm, [e.target.name]: e.target.value });
    }
    //  getProducts(pageLimit,pageOffset, e.target.name === 'sectionId'?e.target.value : '', e.target.name === 'categoryId'?e.target.value : '');
    //  setproductAddForm({ ...productAddForm, [e.target.name]: e.target.value })
  };

  return (
    <main className="container-fluid dashboard">
      <div className="row m-3">
        <div className="col-md-3">
          <div className="form-group">
            <label>Search Product</label>
            <input
              type="text"
              name="name"
              className="form-control"
              value={""}
              placeholder="Enter Product name"
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>Section</label>
            <select
              name="sectionId"
              className="form-select"
              onChange={(e) => handleChanges(e)}
              value={productAddForm.sectionId}
            >
              <option value={""}>{"--Select Section--"}</option>
              {sectionList &&
                sectionList.length > 0 &&
                sectionList.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>

        <div className="col-md-3">
          <div className="form-group">
            <label>Category</label>
            <select
              name="categoryId"
              className="form-select"
              onChange={(e) => handleChanges(e)}
              value={productAddForm.categoryId}
            >
              <option value={""}>{"--Select Category--"}</option>
              {categoryList &&
                categoryList.length > 0 &&
                categoryList.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>

        <div className="col-md-3">
          <div className="form-group">
            <label>Branch</label>
            <select
              name="storeId"
              className="form-select"
              onChange={(e) => handleChanges(e)}
              value={productAddForm.storeId}
            >
              <option value={""}>{"--Select Branch--"}</option>
              {branches &&
                branches.length > 0 &&
                branches.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>

        <div className="col-md-12 card-1 h-scroll">
          <table className="table table-striped table-bordered border-sm  mt-2">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Id</th>
                {/* <th scope="col">Images</th> */}
                {/* <th scope="col">Preview Image</th> */}
                <th scope="col">Name</th>
                <th scope="col">HSN</th>
                <th scope="col" colSpan={8}>
                  Variants
                </th>
                {/* <th scope="col">Status</th> */}
              </tr>
              <tr>
                <th scope="col"></th>
                {/* <th scope="col">Images</th> */}
                {/* <th scope="col"></th> */}
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col">Quantity</th>
                <th scope="col">Dimensions</th>

                <th scope="col">B2B Price</th>
                <th scope="col">B2B Offer</th>
                <th scope="col">B2C Price</th>
                <th scope="col">B2C Offer</th>
                <th scope="col">Status</th>
                <th scope="col">Update</th>
                {/* {isDsplayAddProduct() && <th scope="col"></th>} */}
              </tr>
            </thead>
            {resultList && resultList.length > 0 ? (
              <StorePrdctInlneListItems
                resultList={resultList}
                storeId={productAddForm.storeId}
              />
            ) : (
              <p>No Data found</p>
            )}
          </table>
          <GMCTPagination
            pageCount={pageCount}
            activePage={activePage}
            totalCount={totalCount}
            onPageChange={(page) => handlePageChange(page)}
            onLimitChange={(e) => handleLimitChange(e)}
          />
        </div>
      </div>
    </main>
  );
}
export default StoreInlineProducts;
