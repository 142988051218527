import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./login.css";
import logo from "../assets/logo.jpeg";
import {
  loginAction,
  updateUserActon,
} from "../network/store/action/UserResponseAction";
import { useDispatch, useSelector } from "react-redux";
import { changeStatus, loadingStatus } from "../redux/slice/loaderSlice";
import AppToast from "../components/appToast";
import { updateMessage } from "../redux/slice/toastSlice";
import AppLodingBar from "../components/loader";
import { getFirebaseToken } from "../notifications/firebase";

/**
 *
 * @returns Login Component
 */
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector(loadingStatus);

  // user login form
  const [requestForm, setRequestForm] = useState({
    userName: "",
    password: "",
  });

  const handleChanges = (e) => {
    setRequestForm({ ...requestForm, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    dispatch(changeStatus(false));
  }, []);

  const login = async () => {
    localStorage.setItem("authToken", "");
    dispatch(changeStatus(true));
    let requestObject = {
      username: requestForm.userName,
      password: requestForm.password,
      role: "1",
    };

    await dispatch(loginAction(requestObject, useDispatch)).then((response) => {
      dispatch(changeStatus(false));

      if (response.status) {
        fetchFirebaseToken();
        navigate("/dashboard");
      } else {
        dispatch(
          updateMessage({
            display: true,
            message: "Invalid username or Password",
          })
        );
      }
    });
  };

  async function fetchFirebaseToken() {
    //requesting permission using Notification API
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      getFirebaseToken()
        .then((firebaseToken) => {
          console.log("Firebase token: ", firebaseToken);
          if (firebaseToken) {
            updateNotificationToken(firebaseToken);
          }
        })
        .catch((err) =>
          console.error(
            "An error occured while retrieving firebase token. ",
            err
          )
        );
      //We can send token to server
    } else if (permission === "denied") {
      //notifications are blocked
      alert("You denied for the notification");
    }
  }

  const updateNotificationToken = async (firebaseToken) => {
    let requestBody = {
      token: firebaseToken,
      id: localStorage.getItem("userId"),
      osType: "WEB",
    };

    await dispatch(updateUserActon(requestBody)).then((response) => {
      console.log(response);
    });
  };
  return (
    <>
      {isLoading && <AppLodingBar />}
      {/* login-bg */}
      <div className="login-page">
        <div className="row">
          <div className="login card-1">
            <div>
              <h3 className="text-centers" style={{ color: "#fff" }}>
                <img src={logo} alt="" width="100%"height="80%" />
              </h3>
            </div>
            <div>
              <div className="form-group">
                <label htmlFor="form2Example11">User Name</label>
                <input
                  type="email"
                  name="userName"
                  value={requestForm.userName}
                  onChange={(e) => handleChanges(e)}
                  id="form2Example11"
                  className="form-control"
                  placeholder="Enter email/Mobile number"
                />
              </div>
              <div className="form-group">
                <label htmlFor="form2Example22">Password</label>
                <input
                  type="password"
                  name="password"
                  value={requestForm.password}
                  onChange={(e) => handleChanges(e)}
                  id="form2Example22"
                  className="form-control"
                  placeholder="Enter Password"
                />
              </div>
            </div>
            <div>
              <button
                className="btn btn-purple-bg w-100 text-center"
                onClick={(e) => login(e)}
              >
                LOGIN
              </button>
            </div>
          </div>
        </div>
      </div>

      <AppToast />
    </>
  );
};

export default Login;
