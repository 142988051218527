import React, { useContext, useState } from "react";

import {
  FaBars,
  FaCcDinersClub,
  FaUserLock,
  FaCuttlefish,
  FaUserTie,
  FaUsers,
  FaStore,
  FaLemon,
  FaAlignJustify,
  FaImage,
  FaHashtag,
  FaCentercode,
  FaRegDotCircle,
  FaUser,
  FaUserCircle,
  FaCube,
} from "react-icons/fa";
import { FaClipboardUser } from "react-icons/fa6";
import {
  TbRecharging,
  TbCricket,
  TbPlayFootball,
  TbReportAnalytics,
  TbAlignBoxBottomCenterFilled,
} from "react-icons/tb";
import {
  AiFillDashboard,
  AiOutlineWallet,
  AiOutlineTransaction,
  AiOutlineDribbble,
} from "react-icons/ai";
import { GiHound, GiRingmaster } from "react-icons/gi";
import { GrTransaction } from "react-icons/gr";
import { CgGames } from "react-icons/cg";
import { FcSportsMode, FcCurrencyExchange } from "react-icons/fc";
import { PiNewspaperClippingThin } from "react-icons/pi";
import {
  MdSportsKabaddi,
  MdSportsTennis,
  MdSportsHockey,
  MdDashboard,
  MdCasino,
  MdAccountBox,
  MdSupportAgent,
  MdHistoryEdu,
  MdOutlineBrandingWatermark,
  MdInvertColors,
  MdOutlineCurrencyRupee,
} from "react-icons/md";
import { RiCouponFill } from "react-icons/ri";
import { Navigate, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import SidebarLink from "./sidebar-link";
import { Container, Navbar } from "react-bootstrap";
import {
  checkUserAccess,
  isAuthenticated,
  logoutUser,
} from "../../network/service/UserService";

import AppLogo from "../../assets/logo_text.jpeg";
import { HiReceiptTax } from "react-icons/hi";
import { SiMaterialdesign } from "react-icons/si";

const Sidebar = ({ children }) => {
  let addressUrl = new URL(window.location.href);
  let pathName = addressUrl.pathname.split("/");
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [subnav, setSubnav] = useState(false);
  const [activeMenu, setActiveMenu] = useState("");
  const [subnavIndex, setSubnavIndex] = useState();

  const toggle = () => setIsOpen(!isOpen);
  const showSubNav = (mainIndex) => {
    setSubnav(!subnav);
    setSubnavIndex(mainIndex);
  };

  const logout = async () => {
    await localStorage.setItem("authToken", "");
    await logoutUser();
    navigate("/login");
  };

  const menuItem = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: <MdDashboard />,
      subnav: [],
      roles: ["1", "2", "3"],
    },

    {
      path: "",
      name: "Employee Track",
      icon: <FaStore />,
      subnav: [
        {
          path: "/tls",
          name: "Today Logins",
          icon: <FaStore />,
          subnav: [],
          roles: ["1"],
        },
        {
          path: "/gMaps",
          name: "Maps",
          icon: <FaStore />,
          subnav: [],
          roles: ["1"],
        },
        {
          path: "/attendance",
          name: "Attendance",
          icon: <FaLemon />,
          subnav: [],
          roles: ["1", "2", "3"],
        },
      ],
      roles: ["1"],
    },
    {
      path: "/stores",
      name: "Branches",
      icon: <FaStore />,
      subnav: [],
      roles: ["1"],
    },
    {
      path: "/products",
      name: "Products",
      icon: <FaLemon />,
      subnav: [],
      roles: ["1", "2", "3"],
    },
    {
      path: "",
      name: "Price Changes",
      icon: <MdOutlineCurrencyRupee />,
      subnav: [
        {
          path: "/apc",
          name: "Admin Price Update",
          icon: <MdOutlineCurrencyRupee />,
          subnav: [],
          roles: ["1", "2", "3"],
        },
        {
          path: "/spc",
          name: "Store Price Update",
          icon: <MdOutlineCurrencyRupee />,
          subnav: [],
          roles: ["1", "2", "3"],
        },
      ],
      roles: ["1", "2", "3"],
    },

    {
      path: "",
      name: "Coupons",
      icon: <RiCouponFill />,
      subnav: [
        {
          path: "/all",
          name: "All Coupons",
          icon: <RiCouponFill />,
          subnav: [],
          roles: ["1", "2", "3"],
        },
        {
          path: "/storecoupons",
          name: "Store Coupons",
          icon: <RiCouponFill />,
          subnav: [],
          roles: ["1", "2", "3"],
        },
        // {
        //   path: "/locationCoupons",
        //   name: "Location Coupons",
        //   icon: <RiCouponFill />,
        //   subnav: [],
        //   roles: ["1", "2", "3"],
        // },
      ],
      roles: ["1", "2", "3"],
    },

    {
      path: "",
      name: "Orders",
      icon: <FaAlignJustify />,
      subnav: [
        {
          path: "/porders",
          name: "Orders",
          icon: <MdCasino />,
          subnav: [],
          roles: ["1", "2", "3"],
        },
        {
          path: "/corder",
          name: "Create Order",
          icon: <MdCasino />,
          subnav: [],
          roles: ["1", "2", "3"],
        },
      ],
      roles: ["1", "2", "3"],
    },

    {
      path: "/banners",
      name: "Banners",
      icon: <FaImage />,
      subnav: [],
      roles: ["1", "2", "3"],
    },
    {
      path: "",
      name: "Product Util",
      icon: <FaBars />,
      subnav: [
        {
          path: "/categories",
          name: "Categories",
          icon: <FaRegDotCircle />,
          subnav: [],
          roles: ["1", "2", "3"],
        },
        {
          path: "/subcategories",
          name: "Sub Categories",
          icon: <FaRegDotCircle />,
          subnav: [],
          roles: ["1", "2", "3"],
        },
        {
          path: "/uom",
          name: "Unit Of Measure",
          icon: <FaBars />,
          subnav: [],
          roles: ["1", "2", "3"],
        },
        {
          path: "/dimensions",
          name: "Dimensions",
          icon: <FaCube />,
          subnav: [],
          roles: ["1", "2", "3"],
        },
        {
          path: "/brands",
          name: "Brands",
          icon: <MdOutlineBrandingWatermark />,
          subnav: [],
          roles: ["1", "2", "3"],
        },
        {
          path: "/tags",
          name: "Tags",
          icon: <FaHashtag />,
          subnav: [],
          roles: ["1", "2", "3"],
        },
        {
          path: "/taxSlabs",
          name: "Tax Slabs",
          icon: <HiReceiptTax />,
          subnav: [],
          roles: ["1", "2", "3"],
        },

        {
          path: "/hsn",
          name: "HSN Codes",
          icon: <FaCentercode />,
          subnav: [],
          roles: ["1", "2", "3"],
        },
        {
          path: "/colors",
          name: "Colors",
          icon: <MdInvertColors />,
          subnav: [],
          roles: ["1", "2", "3"],
        },
        {
          path: "/mtypes",
          name: "Material Types",
          icon: <SiMaterialdesign />,
          subnav: [],
          roles: ["1", "2", "3"],
        },
      ],
      roles: ["1", "2", "3"],
    },

    {
      path: "",
      name: "Users",
      icon: <FaUser />,
      subnav: [
        {
          path: "/customers",
          name: "Customers",
          icon: <FaUserCircle />,
          subnav: [],
          roles: ["1", "2", "3"],
        },
        {
          path: "/b2bc",
          name: "B2B Customers",
          icon: <FaRegDotCircle />,
          subnav: [],
          roles: ["1", "2", "3"],
        },
        {
          path: "/dealers",
          name: "Dealers",
          icon: <FaRegDotCircle />,
          subnav: [],
          roles: ["1", "2", "3"],
        },
        {
          path: "/beu",
          name: "Branch Executives",
          icon: <FaRegDotCircle />,
          subnav: [],
          roles: ["1", "2", "3"],
        },
        {
          path: "/deliveryBoys",
          name: "Delivery Executives",
          icon: <FaRegDotCircle />,
          subnav: [],
          roles: ["1", "2", "3"],
        },
        {
          path: "/admin",
          name: "Admins",
          icon: <FaRegDotCircle />,
          subnav: [],
          roles: ["1", "2", "3"],
        },
        {
          path: "/semp",
          name: "Employees",
          icon: <FaRegDotCircle />,
          subnav: [],
          roles: ["1", "2", "3"],
        },
      ],
      roles: ["1", "2", "3"],
    },

    // {
    //   path: "/wallet",
    //   name: "Wallet",
    //   icon: <FaLemon />,
    //   subnav: [],
    //   roles: ["1", "2", "3"],
    // },
    {
      path: "",
      name: "Reports",
      icon: <FaCcDinersClub />,
      subnav: [
        // {
        //   path: "/purchases",
        //   name: "Purchases",
        //   icon: <FaCcDinersClub />,
        //   subnav: [],
        //   roles: ["1", "2", "3"],
        // },
        // {
        //   path: "/expenses",
        //   name: "Expenses",
        //   icon: <FaCcDinersClub />,
        //   subnav: [],
        //   roles: ["1", "2", "3"],
        // },
        {
          path: "/sales",
          name: "Sales",
          icon: <FaCcDinersClub />,
          subnav: [],
          roles: ["1", "2", "3"],
        },
      ],
      roles: ["1", "2", "3"],
    },

    // {
    //   path: "",
    //   name: "Reports",
    //   icon: <FaCcDinersClub />,
    //   subnav: [
    //     {
    //       path: "/dreports",
    //       name: "Daily Report",
    //       icon: <FaCcDinersClub />,
    //       subnav: [],
    //       roles: ["1", "2", "3"],
    //     },
    //     {
    //       path: "/wreports",
    //       name: "Weekly Reports",
    //       icon: <FaCcDinersClub />,
    //       subnav: [],
    //       roles: ["1", "2", "3"],
    //     },
    //     {
    //       path: "mreports",
    //       name: "Monthly Reports",
    //       icon: <FaCcDinersClub />,
    //       subnav: [],
    //       roles: ["1", "2", "3"],
    //     },
    //     {
    //       path: "yreports",
    //       name: "Yearly Reports",
    //       icon: <FaCcDinersClub />,
    //       subnav: [],
    //       roles: ["1", "2", "3"],
    //     },
    //     {
    //       path: "creports",
    //       name: "Custom Report",
    //       icon: <FaCcDinersClub />,
    //       subnav: [],
    //       roles: ["1", "2", "3"],
    //     },
    //   ],
    //   roles: ["1", "2", "3"],
    // },
    // {
    //   path: "/settings",
    //   name: "Settings",
    //   icon: <FaLemon />,
    //   subnav: [],
    //   roles: ["1", "2", "3"],
    // },
  ];

  return (
    <>
      {pathName[1] !== "login" && isAuthenticated() ? (
        <div className="sidenav-layout">
          <div
            style={{ width: isOpen ? "60px" : "200px", transition: "all 0.5s" }}
            className="sidebar"
          >
            <div className="top_section">
              {/* <img
                style={{
                  display: isOpen ? "none" : "block",
                  height: 35,
                }}
                className="logo"
                src=""
              /> */}

              <p
                style={{
                  display: isOpen ? "none" : "block",
                  padding: "10px",
                  color: "#FFFFFF",
                }}
              >
                 ADMIN
              </p>
              <div
                style={{ marginLeft: isOpen ? "10px" : "auto" }}
                className="bars"
              >
                <FaBars onClick={toggle} />
              </div>
            </div>
            <div className="mnu">
              {menuItem.map(
                (item, menuIndex) =>
                  // <SidebarLink item={item} isMenuExpand={isOpen} />
                  checkUserAccess(item.roles) && (
                    <SidebarLink
                      key={menuIndex}
                      item={item}
                      isMenuExpand={isOpen}
                      activeMenu={activeMenu}
                      selectedMenu={(menu) => {
                        setActiveMenu(menu);
                      }}
                    />
                  )
              )}
            </div>
          </div>

          <div
            className="dsply-desktp"
            style={{
              position: "Fixed",
              width: "100%",
              zIndex: 9,
              // marginLeft: isOpen ? "200px" : "60px"
            }}
          >
            <Navbar collapseOnSelect expand="lg">
              <div className="container-fluid">
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="me-auto">
                    {/* <img
                      src={AppLogo}
                      alt="Biyss"
                      style={{
                        height: 50,
                        width: 500,
                        marginLeft: isOpen ? 70 : 220,
                      }}
                    /> */}
                  </Nav>
                  <Nav className="me-auto"></Nav>
                  <Nav>
                    <NavDropdown
                      title={
                        <img
                          style={{ height: 30, width: 28 }}
                          className="logo"
                          src="https://cdn.icon-icons.com/icons2/1919/PNG/512/avatarinsideacircle_122011.png"
                        />
                      }
                      id="collasible-nav-dropdown"
                    >
                      {/* <NavDropdown.Item
                        onClick={() => navigate("/changepassword")}
                      >
                        Change Password
                      </NavDropdown.Item> */}
                      <NavDropdown.Item onClick={() => logout()}>
                        Logout
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </Navbar.Collapse>
              </div>
            </Navbar>
          </div>

          <div className="mble-dsply mble-dsply1">
            <div
              className="mnu"
              style={{ marginLeft: isMobileMenuOpen ? "0px" : "-100%" }}
            >
              {menuItem.map(
                (item, menuIndex) =>
                  // <SidebarLink item={item} isMenuExpand={isOpen} />
                  checkUserAccess(item.roles) && (
                    <SidebarLink
                      key={menuIndex}
                      item={item}
                      isMenuExpand={isOpen}
                      activeMenu={activeMenu}
                      selectedMenu={(menu) => {
                        setActiveMenu(menu);
                        setIsMobileMenuOpen(false);
                      }}
                    />
                  )
              )}
            </div>
            <div className="row p-1">
              <div className="col-4 mt-2 mb-1">
                <div
                  className="mobile-menu-toggle"
                  onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                >
                  <FaBars />
                </div>
              </div>
              <div className="col-6 mt-3">
                <img
                  style={{ width: 140, marginLeft: -20 }}
                  className="logo"
                  src=""
                  onClick={() => navigate("/dashboard")}
                />
              </div>
              <div className="col-2 mt-3">
                <NavDropdown
                  title={
                    <img
                      style={{ height: 30, width: 28 }}
                      className="logo"
                      src="https://cdn.icon-icons.com/icons2/1919/PNG/512/avatarinsideacircle_122011.png"
                    />
                  }
                  id="collasible-nav-dropdown"
                >
                  {/* <NavDropdown.Item onClick={() => navigate("/changepassword")}>
                    Change Password
                  </NavDropdown.Item> */}
                  <NavDropdown.Item onClick={() => logout()}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            </div>
          </div>

          <main
            className="main-layout"
            style={{
              marginLeft: isOpen ? "60px" : "200px",
            }}
          >
            {/* <div className="mble-dsply">
              <div className="row mt-3">
                <div className="col-6">
                  <label>Main Balance: </label>
                </div>
              </div>
            </div> */}

            <div>{children}</div>
          </main>
        </div>
      ) : (
        <div>{children}</div>
      )}
    </>
  );
};

export default Sidebar;
