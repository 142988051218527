import moment from "moment-timezone";

export const todayBetweenDates = (startDate, endDate) => {
  const today = moment(moment(new Date()).format("YYYY-MM-DD"), "YYYY-MM-DD");

  console.log(today);

  if (today.isBetween(startDate, endDate, "days", true)) {
    return "LIVE";
  } else if (today.isSameOrBefore(startDate)) {
    return "Scheduled";
  } else if (today.isAfter(endDate)) {
    return "Completed";
  } else {
    return "Scheduled";
  }
};

export const isAfterDate = (startDate, endDate) => {
  const eDate = moment(
    moment(new Date(endDate)).format("YYYY-MM-DD"),
    "YYYY-MM-DD"
  );

  if (eDate.isSameOrAfter(startDate, "days", true)) {
    return true;
  } else {
    return false;
  }
};

export const todayDate = () => {
  return moment(new Date()).format("YYYY-MM-DD");
};

export const todayDateDisplay = () => {
  return moment(new Date()).format("DD MMM, YYYY");
};

export const orderFormatedDate = (oDate) => {
  const a = moment(oDate, "YYYY-MM-DD HH:mm.ss").format("MMM DD, YYYY hh:mm A");
  return a;
};

export const timeBetween = (timeOut, timeIn) => {
  var ms = moment(timeOut, "HH:mm:ss").diff(moment(timeIn, "HH:mm:ss"));
  var d = moment.duration(ms);
  // console.log(d.days() + ':' + d.hours() + ':' + d.minutes() + ':' + d.seconds());
  return `${d.hours() + " hr  " + d.minutes() + " mins."}`;
};

export const timeBetweenHrs = (timeOut, timeIn) => {
  if (validTime(timeOut, timeIn)) {
    var ms = moment(timeOut, "HH:mm:ss").diff(moment(timeIn, "HH:mm:ss"));
    var d = moment.duration(ms);
    return d.hours();
  } else {
    return "-";
  }
};

export const timeBetweenMins = (timeOut, timeIn) => {
  if (validTime(timeOut, timeIn)) {
    var ms = moment(timeOut, "HH:mm:ss").diff(moment(timeIn, "HH:mm:ss"));
    var d = moment.duration(ms);
    return d.minutes();
  } else {
    return "-";
  }
};

export const validTime = (timeOut, timeIn) => {
  var isValidTimeOut = moment(timeOut, "HH:mm:ss").isValid();
  var isValidTimeIn = moment(timeIn, "HH:mm:ss").isValid();

  var isTimeOutAfter = moment(timeOut, "HH:mm:ss").isSameOrAfter(
    moment(timeIn, "HH:mm:ss")
  );

  return isValidTimeOut && isValidTimeIn && isTimeOutAfter ? true : false;
};

export const isAfterTime = (timeOut, timeIn) => {
  return moment(timeOut, "HH:mm:ss").isSameOrAfter(moment(timeIn, "HH:mm:ss"));
};

// WORKING EXAMPLE DATE AND TIME DIFF - Bharath.
// var now  = "04/09/2013 15:00:00";
// var then = "02/09/2013 14:20:30";

// var ms = moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(then,"DD/MM/YYYY HH:mm:ss"));
// var d = moment.duration(ms);

// console.log(d.days() + ':' + d.hours() + ':' + d.minutes() + ':' + d.seconds());
